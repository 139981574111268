<div class="flex flex-row gap-2 items-center" *ngIf="user$ | async; let user">
  <div class="avatar" *ngIf="user?.profileImage">
    <div class="w-10 rounded-full">
      <img [src]="user.profileImage" />
    </div>
  </div>
  <div
    class="w-10 h-10 flex items-center justify-center shrink-0"
    *ngIf="!user?.profileImage">
    <fa-icon [icon]="faUserTie"></fa-icon>
  </div>
  <div *ngIf="user.name">
    <p>{{ user.name }}</p>
  </div>
  <div *ngIf="!user.name">
    <p>{{ user.firstName }} {{ user.lastName }}</p>
  </div>
</div>

<div class="flex flex-row gap-2 items-center" *ngIf="name">
  <div class="w-10 h-10 flex items-center justify-center shrink-0">
    <fa-icon [icon]="faUserTie"></fa-icon>
  </div>
  <div>
    <p>{{ name }}</p>
  </div>
</div>
