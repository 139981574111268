export const carrierMap: {
  [key: string]: {
    name: string;
    profileImage?: string;
    color?: string;
    // colorHsl?: string;
    colorOklch?: string;
    textColorOklch?: string;
  };
} = {
  HMN: {
    name: 'Humana',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/humana.svg',
    color: '#78be1c',
    // colorHsl: '86 74% 43%',
    colorOklch: '72.72% 0.193 131.67',
    textColorOklch: '100% 0 0',
  },
  UHC: {
    name: 'United Healthcare',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/united-healthcare.svg',
    color: '#293682',
    // colorHsl: '231 52% 34%',
    colorOklch: '36.96% 0.128 271.36',
    textColorOklch: '100% 0 0',
  },
  ATN: {
    name: 'Aetna',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/aetna.svg',
    color: '#7d3d98',
    // colorHsl: '282 43% 42%',
    colorOklch: '48.2% 0.152 314.66',
    textColorOklch: '100% 0 0',
  },
  WLLCRE: {
    name: 'Wellcare',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/wellcare.svg',
    color: '#00a0a3',
    // colorHsl: '181 100% 32%',
    colorOklch: '64.01% 0.10894214227275134 197.13938215621135',
    textColorOklch: '100% 0 0',
  },
  CGN: {
    name: 'Cigna',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/cigna.svg',
    color: '#0033ff',
    // colorHsl: '228 100% 50%',
    colorOklch: '48.49% 0.2910725762416165 264.12120589250776',
    textColorOklch: '100% 0 0',
  },
  // ANTM: {
  //   name: 'Anthem',
  //   profileImage: 'https://app.enrollhere.com/assets/img/carrier/anthem.svg',
  //   color: '#1355e9',
  //   // colorHsl: '221 85% 49%',
  //   colorOklch: '51.45% 0.232 262.95',
  //   textColorOklch: '100% 0 0',
  // },
  ANTM: {
    name: 'Anthem',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/anthem.png',
    color: '#027DBB',
    // colorHsl: '221 85% 49%',
    colorOklch: '56.4% 0.131 241.24',
    textColorOklch: '100% 0 0',
  },
  AMG: {
    name: 'Amerigroup',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/amerigroup.svg',
    color: '#017eb3',
    colorOklch: '56.17% 0.122 236.79',
    textColorOklch: '100% 0 0',
  },
  CPL: {
    name: 'CarePlus',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/careplus.svg',
    color: '#c3202f',
    colorOklch: '52.85% 0.195 23.35',
    textColorOklch: '100% 0 0',
  },
  DVTD: {
    name: 'Devoted Health',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/devoted.svg',
    color: '#ff4f00',
    colorOklch: '66.96% 0.222 37.42',
    textColorOklch: '100% 0 0',
  },
  ALGN: {
    name: 'Alignment Health Plan',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/alignment.svg',
    color: '#003a5d',
    // colorHsl: '203 100% 18%',
    colorOklch: '33.51% 0.08155274152686817 243.33747228905736',
    textColorOklch: '100% 0 0',
  },
  MLNA: {
    name: 'Molina Healthcare',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/molina.svg',
    color: '#089ea0',
    colorOklch: '63.43% 0.107 196.4',
    textColorOklch: '100% 0 0',
  },
  CNC: {
    name: 'Centene Corporation',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/centene.svg',
    color: '#00548c',
    colorOklch: '43.42% 0.11492136846224678 247.2812596043368',
    textColorOklch: '100% 0 0',
  },
  SMP: {
    name: 'Simply Healthcare',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/simply.svg',
    color: '#14c1dc',
    colorOklch: '74.58% 0.128 212.66',
    textColorOklch: '100% 0 0',
  },
  IBC: {
    name: 'Independence Blue Cross',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/independence.svg',
    color: '#2191cb',
    colorOklch: '62.42% 0.128 237.83',
    textColorOklch: '100% 0 0',
  },
  OPTM: {
    name: 'Optimum HealthCare',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/optimum.svg',
    color: '#9acabe',
    colorOklch: '80.17% 0.053 177.82',
    textColorOklch: '100% 0 0',
  },
  AHA: {
    name: 'Allina Health Aetna',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/allina.svg',
    color: '#36a5d4',
    colorOklch: '68.07% 0.119 230.89',
    textColorOklch: '100% 0 0',
  },
  ELE: {
    name: 'Elevance',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/elevance.svg',
    color: '#173478',
    colorOklch: '34.75% 0.122 263.78',
    textColorOklch: '100% 0 0',
  },
  HMK: {
    name: 'Highmark',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/highmark.svg',
    color: '#0da2e6',
    colorOklch: '67.6% 0.147 237.72',
    textColorOklch: '100% 0 0',
  },
  MOO: {
    name: 'Mutual Of Omaha',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/mutual-of-omaha.svg',
    color: '#003a71',
    colorOklch: '34.97% 0.109 253.39',
    textColorOklch: '100% 0 0',
  },
  SEN: {
    name: 'Sentara Health',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/optima.svg',
    color: '#fcb719',
    colorOklch: '82.25% 0.166 80.32',
    textColorOklch: '100% 0 0',
  },
  UPMC: {
    name: 'UPMC Health Plan',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/upmc.svg',
    color: '#501141',
    colorOklch: '30.64% 0.109 339.16',
    textColorOklch: '100% 0 0',
  },
  FRDM: {
    name: 'Freedom Health',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/freedom.svg',
    color: '#F11618',
    colorOklch: '60.74% 0.241 28.3',
    textColorOklch: '100% 0 0',
  },
  JHP: {
    name: 'Jefferson Health Plans',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/jefferson.svg',
    color: '#1C4B6F',
    colorOklch: '39.89% 0.079 244.97',
    textColorOklch: '100% 0 0',
  },
  CBC: {
    name: 'Capital Blue Cross',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/capita-blue-cross.svg',
    color: '#0072cd',
    colorOklch: '54.94% 0.164 252.02',
    textColorOklch: '100% 0 0',
  },
  GWS: {
    name: 'Great Western',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/great-western.webp',
    color: '#1d3b6c',
    colorOklch: '35.71% 0.092 259.73',
    textColorOklch: '100% 0 0',
  },
  FRST: {
    name: 'Foresters',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/foresters.svg',
    color: '#5f2750',
    colorOklch: '36.75% 0.098 338.94',
    textColorOklch: '100% 0 0',
  },
  AMAM: {
    name: 'American Amicable',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/american-amicable.webp',
    color: '#0850ac',
    colorOklch: '44.97% 0.161 258.16',
    textColorOklch: '100% 0 0',
  },
  CRSC: {
    name: 'CareSource',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/care-source.webp',
    color: '#753b97',
    colorOklch: '46.84% 0.151 310.99',
    textColorOklch: '100% 0 0',
  },
  FRHL: {
    name: 'Friday Health',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/friday-health.svg',
    color: '#8a1a9b',
    colorOklch: '46.68% 0.203 321.8',
    textColorOklch: '100% 0 0',
  },
  MBTR: {
    name: 'Ambetter',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/ambetter.svg',
    color: '#c9187e',
    colorOklch: '55.53% 0.218 352.97',
    textColorOklch: '100% 0 0',
  },
  OSHL: {
    name: 'Oscar Health',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/oscar-health.svg',
    color: '#2353a1',
    colorOklch: '45.41% 0.137 259.74',
    textColorOklch: '100% 0 0',
  },
  SILAC: {
    name: 'SILAC',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/silac.svg',
    color: '#2fa1d9',
    colorOklch: '67.18% 0.128 235.33',
    textColorOklch: '100% 0 0',
  },
  ATHE: {
    name: 'Athene',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/athene.svg',
    color: '#0365ac',
    colorOklch: '49.73% 0.137 249.27',
    textColorOklch: '100% 0 0',
  },
  NLG: {
    name: 'National Life Group',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/national-life-group.svg',
    color: '#d4d5d7',
    colorOklch: '87.28% 0.003 264.54',
    textColorOklch: '100% 0 0',
  },
  OXF: {
    name: 'Oxford',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/oxford.svg',
    color: '#2c72b8',
    colorOklch: '54.32% 0.13 251.51',
    textColorOklch: '100% 0 0',
  },
  NSR: {
    name: 'Nassau Re',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/nassau-re.svg',
    color: '#005B9C',
    colorOklch: '46.22% 0.12783653512428655 249.10411854211054',
    textColorOklch: '100% 0 0',
  },
  PRSP: {
    name: 'Prosperity',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/prosperity.webp',
    color: '#224288',
    colorOklch: '39.67% 0.123 263.38',
    textColorOklch: '100% 0 0',
  },
  UHL: {
    name: 'United Home Life',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/united-home-life.webp',
    color: '#b21f3d',
    colorOklch: '49.87% 0.18 16.53',
    textColorOklch: '100% 0 0',
  },
  LBL: {
    name: 'Liberty Bankers Life',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/liberty-bankers-life.webp',
    color: '#3a51a0',
    colorOklch: '45.97% 0.131 268.64',
    textColorOklch: '100% 0 0',
  },
  AIG: {
    name: 'American General',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/american-general.svg',
    color: '#001871',
    colorOklch: '27.68% 0.15256014422818173 263.73036349771024',
    textColorOklch: '100% 0 0',
  },
  RLNB: {
    name: 'Royal Neighbors',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/royal-neighbors.webp',
    color: '#f36e45',
    colorOklch: '69.25% 0.173 37.62',
    textColorOklch: '100% 0 0',
  },
  GTL: {
    name: 'Guarantee Trust Life',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/guarantee-trust-life.svg',
    color: '#104d78',
    colorOklch: '40.61% 0.093 245.18',
    textColorOklch: '100% 0 0',
  },
  KCL: {
    name: 'Kansas City Life',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/kansas-city-life.svg',
    color: '#02518a',
    colorOklch: '42.57% 0.115 248.65',
    textColorOklch: '100% 0 0',
  },
  MNL: {
    name: 'Manhattan Life',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/manhattan-life.svg',
    color: '#06a7a0',
    colorOklch: '65.74% 0.113 189.49',
    textColorOklch: '100% 0 0',
  },
  SSL: {
    name: 'Sentinel Security Life',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/sentinel-security.svg',
    color: '#980428',
    colorOklch: '43.2% 0.171 18.9',
    textColorOklch: '100% 0 0',
  },
  GBL: {
    name: 'Gerber Life',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/gerber-life.webp',
    color: '#03427c',
    colorOklch: '37.86% 0.114 252.9',
    textColorOklch: '100% 0 0',
  },
  HLNL: {
    name: 'Heartland National',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/heartland-national.svg',
    color: '#fba502',
    colorOklch: '78.8% 0.168 72.01',
    textColorOklch: '100% 0 0',
  },
  NCD: {
    name: 'National Care Dental',
    profileImage: 'https://app.enrollhere.com/assets/img/carrier/national-care-dental.svg',
    color: '#007eac',
    colorOklch: '55.8% 0.115 233',
    textColorOklch: '100% 0 0',
  },

  HPP: {
    name: 'Health Partners Plans',
  },

  PHH: {
    name: 'PH Holdings',
  },
};
