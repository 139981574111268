import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  show(title: string, message?: string, colorClass?: string) {
    const toastContainer = document.createElement('div');
    toastContainer.className = 'toast toast-top z-50';
    toastContainer.style.animation = 'fade-in 0.5s ease-in-out';
    const toastContent = document.createElement('div');

    const classes = ['alert', 'flex-col', 'flex', 'items-start', 'gap-2'];
    if (colorClass) {
      classes.push(colorClass);
    } else {
      classes.push('alert-info');
    }

    toastContent.className = classes.join(' ');
    const toastTitle = document.createElement('h4');
    toastTitle.innerText = title;
    toastContent.appendChild(toastTitle);

    if (message) {
      const toastMessage = document.createElement('span');
      toastMessage.innerText = message;
      toastContent.appendChild(toastMessage);
    }

    toastContainer.appendChild(toastContent);
    document.body.appendChild(toastContainer);
    setTimeout(() => {
      toastContainer.style.animation = 'fade-out 0.5s ease-in-out';
      setTimeout(() => {
        document.body.removeChild(toastContainer);
      }, 500);
    }, 5000);
  }

  success(title: string, message?: string) {
    // this.toastr.success(message, title);
    this.show(title, message, 'alert-success');
  }

  info(title: string, message?: string) {
    // this.toastr.info(message, title);
    this.show(title, message);
  }

  error(title: string, message?: string) {
    // this.toastr.error(message, title);
    this.show(title, message, 'alert-error');
  }

  warning(title: string, message?: string) {
    // this.toastr.warning(message, title);
    this.show(title, message, 'alert-warning');
  }

  hide(toast: any) {
    // this.toastr.clear(toast);
  }

  prompt = (
    title: string,
    message?: string,
    okButtonText = 'Ok',
    dismissButtonText = 'Dismiss'
  ) =>
    new Promise(resolve => {
      const toastContainer = document.createElement('div');
      toastContainer.className = 'toast toast-top toast-end flex';
      toastContainer.style.zIndex = '100';
      const toastContent = document.createElement('div');
      toastContent.className = 'alert alert-info';

      const toastTitle = document.createElement('h4');
      toastTitle.innerText = title;
      toastContent.appendChild(toastTitle);

      if (message) {
        const toastMessage = document.createElement('span');
        toastMessage.innerText = message;
        toastContent.appendChild(toastMessage);
      }

      const buttonContainer = document.createElement('div');
      const okButton = document.createElement('button');
      okButton.className = 'btn btn-white btn-sm mr-2';
      okButton.innerText = okButtonText;
      okButton.addEventListener('click', () => {
        // User clicked OK
        // Perform the action here
        resolve(true);
        document.body.removeChild(toastContainer);
      });
      const dismissButton = document.createElement('button');
      dismissButton.innerText = dismissButtonText;
      dismissButton.className = 'btn btn-white btn-outline btn-sm';
      dismissButton.addEventListener('click', () => {
        // User clicked Cancel
        // Handle the cancellation here
        resolve(false);
        document.body.removeChild(toastContainer);
      });
      buttonContainer.appendChild(okButton);
      buttonContainer.appendChild(dismissButton);
      toastContent.appendChild(buttonContainer);

      toastContainer.appendChild(toastContent);
      document.body.appendChild(toastContainer);
    });
}
