import {
  IconDefinition,
  faCheckCircle,
  faHourglass,
  faQuestionCircle,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { Commission_Origin, Commission_Rate, CommissionType } from '../models';
import {
  CommissionStatus,
  Commission_PlanType,
} from '../models/classes/commissions';

export const commissionOriginMap: { [key: number]: { title: string } } = {
  [Commission_Origin.OR_DIRECT_FROM_CARRIER]: {
    title: 'Direct from Carrier',
  },
  [Commission_Origin.OR_FROM_PARENT]: {
    title: 'From Parent',
  },
};

export const commissionRateMap: { [key: number]: { title: string } } = {
  [Commission_Rate.RT_INITIAL]: {
    title: 'Initial',
  },
  [Commission_Rate.RT_REPLACEMENT]: {
    title: 'Replacement',
  },
  [Commission_Rate.RT_INITIAL_FYC]: {
    title: 'Initial FYC',
  },
  [Commission_Rate.RT_MONTHLY_RENEWAL]: {
    title: 'Monthly Renewal',
  },
  [Commission_Rate.RT_MONTHLY_RENEWAL_AFTER_3_YEARS]: {
    title: 'Monthly Renewal After 3 Years',
  },
  [Commission_Rate.RT_YEARLY_RENEWAL]: {
    title: 'Yearly Renewal',
  },
  [Commission_Rate.RT_INITIAL_TRUE_UP_PRORATED]: {
    title: 'Initial True Up Prorated',
  },
  [Commission_Rate.RT_INITIAL_TRUE_UP_FULLYEAR]: {
    title: 'Initial True Up Full Year',
  },
  [Commission_Rate.RT_INITIAL_FULLYEAR]: {
    title: 'Initial Full Year',
  },
  [Commission_Rate.RT_REPLACEMENT_FULLYEAR]: {
    title: 'Replacement Full Year',
  },
  [Commission_Rate.RT_INITIAL_CC]: {
    title: 'Initial Call Center',
  },
  [Commission_Rate.RT_REPLACEMENT_CC]: {
    title: 'Replacement Call Center',
  },
  [Commission_Rate.RT_MONTHLY_RENEWAL_CC]: {
    title: 'Monthly Renewal Call Center',
  },
  [Commission_Rate.RT_INITIAL_CC_FULLYEAR]: {
    title: 'Initial Call Center Full Year',
  },
  [Commission_Rate.RT_REPLACEMENT_CC_FULLYEAR]: {
    title: 'Replacement Call Center Full Year',
  },
  [Commission_Rate.RT_HEAP_INITIAL]: {
    title: 'Heap Initial',
  },
  [Commission_Rate.RT_HEAP_RECON]: {
    title: 'Heap Recon',
  },
  [Commission_Rate.RT_CUSTOM]: {
    title: 'Custom',
  },
};

export const commissionTypeMap: { [key: number]: { title: string } } = {
  [CommissionType.CT_STREET_LEVEL]: {
    title: 'Street Level',
  },
  [CommissionType.CT_OVERRIDE]: {
    title: 'Override',
  },
  [CommissionType.CT_HEAP]: {
    title: 'Heap',
  },
};

export const planTypeMap: { [key: number]: { title: string } } = {
  [Commission_PlanType.CPT_MAPD_MA]: {
    title: 'MAPD, MA',
  },
  [Commission_PlanType.CPT_PDP]: {
    title: 'PDP',
  },
  [Commission_PlanType.CPT_CUSTOM]: {
    title: 'Custom',
  },
};

export const commissionStatusMap: {
  [key: number]: { title: string; icon: IconDefinition; color: string };
} = {
  [CommissionStatus.CS_MISSING_IDENTIFIED_RESULT]: {
    title: 'Missing Identified Result',
    icon: faQuestionCircle,
    color: 'red',
  },
  [CommissionStatus.CS_MISSING_IDENTIFIED_RESULT_SINGLE]: {
    title: 'Missing Identified Result Single',
    icon: faQuestionCircle,
    color: 'red',
  },
  [CommissionStatus.CS_PENDING]: {
    title: 'Pending',
    icon: faHourglass,
    color: 'yellow',
  },
  [CommissionStatus.CS_PENDING_PROCESS_BATCH]: {
    title: 'Pending Process Batch',
    icon: faHourglass,
    color: 'yellow',
  },
  [CommissionStatus.CS_ERROR]: {
    title: 'Error',
    icon: faTimesCircle,
    color: 'red',
  },
  [CommissionStatus.CS_SUCCESS]: {
    title: 'Success',
    icon: faCheckCircle,
    color: 'green',
  },
};

export const monthMap: { [key: string]: string } = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
};
