import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class ModalContainerService {
  private modals: ShowModalContainer[] = [];

  private _showModals$ = new BehaviorSubject<ShowModalContainer[]>([]);
  showModals$ = this._showModals$.asObservable();

  subscriptionMap: { [key: string]: BehaviorSubject<any> } = {};

  currentEvents$?: BehaviorSubject<any>;
  isOpen = false;

  constructor() {}

  open(component: ShowModalComponents, data?: any) {
    // if (this.isOpen) {
    //   this.close();
    // }
    // this.currentEvents$ = new BehaviorSubject<any>(null);
    const id = uuid();
    this.subscriptionMap[id] = new BehaviorSubject<any>(null);

    setTimeout(() => {
      this.isOpen = true;
      this.modals.push({ id, show: true, component, data });
      this._showModals$.next(this.modals);
      // this._showModal$.next({ show: true, component, data });
    });

    // return this.currentEvents$;
    return this.subscriptionMap[id];
  }

  close(id: string) {
    // this._showModal$.next({
    //   show: false,
    // });
    // this.isOpen = false;
    // this.currentEvents$?.complete();
    // delete this.currentEvents$;

    // for (const modal of this.modals) {
    //   if (modal.id === id) {
    //     modal.show = false;
    //   }
    // }

    const index = this.modals.findIndex(m => m.id === id);

    if (index > -1) {
      this.modals.splice(index, 1);
    }
    console.log('modals', this.modals);
    this._showModals$.next(this.modals);
  }
}

export interface ShowModalContainer {
  id?: string;
  show: boolean;
  component?: ShowModalComponents;
  data?: any;
}

export enum ShowModalComponents {
  COMMISSION = 'COMMISSION',
  ENROLLMENT = 'ENROLLMENT',
  PAYOUT = 'PAYOUT',
  PAYOUT_OVERVIEW = 'PAYOUT_OVERVIEW',
  POLICY_UPDATE = 'POLICY_UPDATE',
}
